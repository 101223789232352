<template>
  <div class="add" :class="{ wide }">
    <div class="ad-banner">Advertisement</div>
    <ion-button class="remove-btn" @click="openSubscribeModal">
      <i class="ti-close mr-1" />
      Remove ads
    </ion-button>
    <div class="loading d-flex align-items-center justify-content-center">
      <ion-skeleton-text animated />
      <div class="position-absolute small no-data">Loading AD</div>
    </div>
    <ins
      class="adsbygoogle"
      :id="`3061056183-${index}`"
      style="display: block; z-index: 2"
      data-ad-layout-key="-6q+ek+16-3w+4d"
      data-ad-client="ca-pub-1095979914738976"
      data-ad-slot="3061056183"
      data-ad-format="fluid"
    ></ins>
  </div>
</template>

<script setup lang="ts">
import { openSubscribeModal } from '@/shared/utils/modals';

defineProps({
  index: {},
  wide: {
    default: false,
  },
});

onMounted(() => {
  try {
    // @ts-ignore
    (adsbygoogle = window.adsbygoogle || []).push({});
  } catch (error) {}
});
</script>

<style lang="sass" scoped>
.ad-banner
  width: 100%
  height: 30px
  background: var(--ion-color-primary)
  color: white
  display: flex
  justify-content: center
  align-items: center
.remove-btn
  position: absolute
  right: 5px
  --height: 25px
  font-size: 14px
  --border-radius: 20px
  pointer-events: all
  z-index: 3
  top: 40px
  margin: 0
  .ti-close
    font-size: 12px
.add
  width: 100%
  border-radius: var(--border-radius-sm)
  overflow: hidden
  position: relative
  height: 300px !important
  ins
    width: 100%
    overflow: hidden
    height: 270px !important

.adsbygoogle
  z-index: 2
  position: relative
.loading
  position: absolute
  pointer-events: all
  width: 100%
  height: 270px
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm)
  z-index: 1
  ion-skeleton-text
    margin: 0
</style>
